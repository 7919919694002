<template>
  <div class="cart">
    <van-nav-bar
      title="购物车"
      left-text="返回"
      :right-text="editing ? '完成' : '编辑'"
      left-arrow
      @click-left="onClickLeft"
      @click-right="editing = !editing"
    />
    <van-empty v-show="list.length === 0" class="bg-fff h100pc">
      <template #description>
        <div v-if="token">
          <span>购物车空空如也</span>
          <router-link to="/" class="base ml-5">回首页</router-link>
        </div>
        <div v-else>
          <span>您还没登录,</span>
          <router-link to="/account/login" class="base ml-5"
            >去登录</router-link
          >
        </div>
      </template>
    </van-empty>

    <van-checkbox-group v-model="result" style="margin-bottom: 100px">
      <div v-for="(item, index) in list" :key="index" class="card-item">
        <div class="flex">
          <van-checkbox
            v-model="result"
            checked-color="#ee0a24"
            :name="item.cartId"
            icon-size="16px"
            style="width: 30px; margin-right: -6px"
          />
          <div class="flex" @click="JumpToDetail(item.productId)">
            <img :src="item.imgUrl" alt="暂无图片" />
            <div class="flex fdc jc-sb w100">
              <p class="f14">{{ item.masterName }}</p>
              <div class="flex jc-sb aic">
                <span class="f16 red">
                  <span>¥</span>
                  <span>{{ item.price | fMoney }}</span>
                </span>
                <van-stepper
                  v-if="editing"
                  :value="item.buyNum"
                  async-change
                  @plus="changeBuyNum(item, 1)"
                  @minus="changeBuyNum(item, -1)"
                />
                <span v-else>x {{ item.buyNum }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-checkbox-group>

    <van-submit-bar
      :price="totalMoney"
      :button-text="editing ? '删除' : '结算'"
      @submit="onSubmit"
    >
      <van-checkbox checked-color="#ee0a24" :value="result2" @click="selectAll"
        >全选</van-checkbox
      >
    </van-submit-bar>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import $http from "@/utils/http";
export default {
  components: {
    Footer,
  },
  created() {
    this.cartList();
  },
  data() {
    return {
      active: "",
      list: [],
      editing: false, //是否为编辑状态
      value: 1,
      result: [], //选中的checkbox 数组
      //全选
      result2: false,
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
    //根据this.result计算总价
    totalMoney() {
      let list = this.list.filter((cart) => {
        return this.result.includes(cart.cartId);
      });
      let sum = 0;
      list.forEach((item) => {
        sum += item.price * item.buyNum;
      });
      return sum;
    },
  },
  watch: {
    result(newValue, oldValue) {
      this.result2 = false;
      if (this.list.length === this.result.length) {
        this.result2 = true;
      }
    },
  },
  methods: {
    JumpToDetail(id) {
      if (this.editing) return;
      this.$router.push("/product/detail/" + id);
    },
    //全选
    selectAll() {
      this.result2 = !this.result2;
      //为true 说明时全选,否则为全不选
      if (this.result2) {
        //把所有商品的 cartId 放入result 数组
        this.result = this.list.map((item) => item.cartId);
      } else {
        this.result = [];
      }
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    //修改商品数量
    changeBuyNum(item, num) {
      let buyNum = item.buyNum + num;
      let params = {
        cartId: item.cartId,
        buyNum,
      };
      $http.post("/cart/updateNum", params).then(() => {
        item.buyNum = buyNum;
      });
    },
    onSubmit() {
      if (this.result.length === 0) {
        this.$toast("请选择一件商品");
        return false;
      }
      //编辑的时候
      if (this.editing) {
        let params = {
          cartId: this.result,
        };

        this.$dialog
          .confirm({
            title: "温馨提示",
            message: "您确定要删除吗?",
          })
          .then(() => {
            $http.post("/cart/del", params).then(() => {
              //找出未被选中的,然后在重新赋值
              this.list = this.list.filter((cart) => {
                return !this.result.includes(cart.cartId);
              });

              this.$toast.success("删除成功");
            });
          })
          .catch(() => {});
        return false;
      }
      //点击结算按钮
      let params = { cartId: this.result, totalMoney: this.totalMoney };
      $http.post("/preOrder/create", params).then((res) => {
        this.$router.push({
          path: "/order/verify",
          query: { preOrderId: res.result.preOrderId },
        });
        //第二种方法
        // this.$router.push('/order/verify?preOrderId='+this.result.preOrderId);
      });
    },
    //购物车列表
    cartList() {
      $http.post("/cart/all").then((res) => {
        this.list = res.list;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.cart /deep/ .van-nav-bar__text,
.cart /deep/.van-icon-arrow-left {
  color: #c03131;
}
.card-item {
  padding: 15px;
  border-bottom: 1px solid #ece9e9;
}
img {
  background: #f9f9f9;
  width: 95px;
  height: 95px;
}

.van-submit-bar {
  bottom: 52px;
}
.red {
  color: #c03131;
}
</style>
